.trail { /* className for the trail elements */
    position: absolute;
    height: 26px; 
    width: 26px;
    border-radius: 3px;
    /* background-image: url('/material/2\ —\ Keimen/Seeds/bigseed1.png'); */
    background-size: contain;
    background-repeat: no-repeat;
    pointer-events: none;
}

#keimen {
    width: 100vw;
    height: 100vh;
    z-index: 10000;
}

.breather {
    position: fixed;
    transition-duration: 1s;
    transition-property: 'opacity';
}

.seed-container {
    z-index: 30;
}

.seeds {
    width: 300px;
    height: auto;
    left: 400px;
    top: 200px;
}

.seeds--2 {
    margin-left: -100px;
    margin-top: -30px;
}

.seeds--3 {
    margin-left: 100px;
    margin-top: 50px;
}

.seeds--4 {
    margin-left: 200px;
    margin-top: -10px;
}

.seeds--5 {
    margin-left: -100px;
    margin-top: 70px;
}

// .userphoto--2{
//     width: 500px;
//     height: 300px;
//     left: 300px;
//     top: 200px;
//     z-index: 2;
// }

// .userphoto--2 img {
//     width: 100%;
//     height: 100%;
//     object-fit: contain;
// }

.keimen__hand {
    width: 500px;
    height: 400px;
    top: 200px;
}

.keimen__datavid {
    width: 600px;
    height: 700px;
    left: 10vw;
}

.keimen__datavid img {
    width: 200px;
}

.keimen__slogan {
    bottom: 150px;
    width: 420px;
    font-family: 'reg';
    font-size: 25px;
    text-align: center;
    position: absolute;
    z-index: 100;
    left: 30px;
    margin-left: 100vw;
    display: none;
    transition-duration: 10s;
    transition-property: margin-left;
    height: 70px;
}

.keimen__slogan p {
    width: 100%;
    margin: 0;
}

.keimen__slogan p:last-child {
    animation: cross 15s linear forwards;
    padding-top: 15px;
    margin-top: -17px;
    overflow: hidden;
}

.keimen__slogan1 {
    width: 100%;
    height: 100%;
    // font-size: 270px;
    font-size: 45vh;
    font-family: 'reg';
    -webkit-text-stroke: white 1px;
    color: transparent;
    top: 0;
    left: 0;
    line-height: 120%;
}

.keimen__slogan1--e, .keimen__slogan1--f {
    font-size: 110px;
    text-align: center;
    line-height: 110%;
}

.keimen__slogan1--c, .keimen__slogan1--d {
    font-size: 30vh;
    line-height: 110%;
}

.keimen__binary {
    width: 600px;
    height: auto;
    top: 100px;
    margin-left: 30px;
    opacity: 0.5;
    z-index: 100000;
}

// .userphoto--2 {
//     width: 75vw;
//     top: calc(50vh - 26vw);
//     left: 12.5vw;
//     z-index: 2;
//     height: 100vh;
// }

.keimen__vege1 {
    width: 100%;
    height: 100%;
    padding: 10vw;
    top: 0;
    left: 5vw;
    z-index: 2;
    box-sizing: border-box;
}

.keimen__vege2 {
    width: calc(100% - 20vw);
    height: calc(100% - 20vw);
    z-index: 3;
    margin-left: 5vw;
}

.keimen__vege1 video, .keimen__vege2 video {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.keimen__computer1 {
    width: 30%;
    height: 30%;
    padding: 200px;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.keimen__background {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-image: url('https://a.storyblok.com/f/107910/1280x802/7968e6c040/branching1.png');
    background-size: 1000px;
    opacity: 0.1;
    z-index: 0;
}

.keimen__text {
    width: 600px;
    right: -200px;
    font-size: 30px;
    font-family: 'reg';
    line-height: 120%;
}

.keimen__text p:hover {
    font-size: 50px;
    line-height: 120%;
    margin-left: -240px;
    cursor: pointer;
    padding-right: 200px;
}

.keimen__seed {
    width: 100px;
    position: absolute;
    height: 100px;
    // margin-top: 100px;
    // margin-left: 100px;
}

.keimen__garden {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding: 100px;
    box-sizing: border-box;
    z-index: 100000000;
}

.keimen__title {
    font-family: 'title';
    font-size: 100px;
    text-align: center;
    width: 100vw;
    height: 100vh;
    line-height: 100%;
    top: 0;
    left: 0;
    margin-top: calc(50vh - 70px);
}

.keimen__title span {
    font-family: 'reg';
    font-size: 20px;
    display: block;
    margin-top: -30px;
}

// .userphoto__info--2 {
//     padding-right: 10px;
//     position: absolute;
//     bottom: 200px;
//     font-family: 'reg';
//     font-size: 25px;
//     // mix-blend-mode: difference;
//     color: white;
//     text-shadow: 2px 2px black;
//     width: 75vw;
//     text-align: right;
//     line-height: 125%;
// }

@keyframes cross {
    0% {
        width: 0%;
        border-top: solid 2px white;
        color: rgba(255,255,255,0);
    }
    70% {
        width: 0%;
        border-top: solid 2px white;
        color: rgba(255,255,255,0);
    }
    90% {
        width: 100%;
        border-top: solid 2px white;
        color: rgba(255,255,255,0);
    }
    100% {
        width: 100%;
        border-top: solid 2px white;
        color: rgba(255,255,255,1);
    }
}

.keimen__slogan2 {
    width: 100%;
    height: 100%;
    pointer-events: none;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
}

.keimen__slogan2 p {
    font-family: 'reg';
    font-size: 20px;
    text-align: left;
    line-height: 115%;
    color: lightgrey;
    animation: blink 0.1s infinite;
}

@keyframes blink {
    0% { display: block; }
    50% { display: none; }
    100% { display: block; }
}

.keimen__slogan-6 {
    animation: blink 0.1s infinite;
    font-size: 20px;
    width: 200px;
}

.keimen__slogan-3, .keimen__slogan-4, .keimen__slogan-5 {
    font-family: 'pixel';
    font-size: 20px;
}

.keimen__slogan-7, .keimen__slogan-8 {
    width: 800px;
    font-family: 'reg';
    font-size: 30px;
}

.keimen__bibli {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    font-family: 'reg';
    font-size: 6vw;
    text-align: center;
    z-index: 1;
    align-items: center;
    justify-content: center;
}

.keimen__bibli p {
    margin: 0;
    animation: bibli 3s infinite ease-in-out;
}

@keyframes bibli {
    0% { margin-top: -20px }
    50% { margin-top: 0px }
    100% { margin-top: -20px }
}

.keimen__floater {
    pointer-events: none;
    width: 200px;
}

.keimen__floater--1 {

}
.keimen__floater--2 {

}
.keimen__floater--3 {

}
.keimen__floater--4 {

}
.keimen__floater--6 {
    width: 600px !important;
}
.keimen__floater--5 {

    width: 400px !important;
}