.saen__flowers {
    width: 100vw;
    height: 0;
    overflow: visible;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: space-around;
    justify-content: space-around;
    z-index: 100000000;
    pointer-events: all;
}
.flower__space {
    // width: 5000px;
}

#saen {
    z-index: 10000000;
}

.saen__flowers img {
    height: 100%;
    vertical-align: bottom;
    display: inline;
    // opacity: 0.4;
    // pointer-events: all;
    transform-origin: bottom;
    object-position: bottom;
}

.flower {
    opacity: 0.4;
    z-index: 100000000;
    filter: grayscale(100%);
}

.flower1{
    animation: wave2 infinite 11s ease-in-out;
    animation-name: glow;
    animation-duration: 1s;
}
.flower2{
    animation: wave infinite 8s ease-in-out;
}
.flower3 {
    animation: wave2 infinite 10s ease-in-out;
}
.flower4{
    animation: wave infinite 9s ease-in-out;
}
.flower5{
    animation: wave2 infinite 7s ease-in-out;
}
.flower6{
    animation: wave infinite 8s ease-in-out;
}
.flower7{
    animation: wave2 infinite 6s ease-in-out;
}

/* .saen-flowers img:nth-child(3) {
    opacity: 1 !important;
} */

.underline {
    text-decoration: underline;
}

.saen__text {
    top: 50px;
    width: 510px;
    font-family: 'reg';
    color: white;
    line-height: 150%;
    font-size: 2.7vh;
    pointer-events: all;
    z-index: 1000000;
}

.saen__text a {
    color: white;
}

.saen__text input {
    background-color: transparent;
    border: none;
    border-bottom: solid 1px white;
    outline: none;
    width: 175px;
    color: white;
    text-transform: uppercase;
    pointer-events: all;
    z-index: 30;
}

.saen__text input::placeholder {
    color: white;
}

.saen__qr {
    width: 120px;
    height: 120px;
    margin-bottom: 30px;
    right: 0;
    float: right;
    margin-left: 20px;
    padding-top: 5px;
}

.saen__qr svg {
    object-fit: contain;
    width: 100%;
    height: 100%;
    transform: scale(0.5);
    top: 0;
    left: 0;
    margin-left: -85px;
    margin-top: -65px;
    border: solid 3px white;
}

.saen__title {
    font-family: 'title';
    font-size: 100px;
    // color: transparent;
    // -webkit-text-stroke: 0.1px white;
    text-align: center;
    width: 100vw;
    margin-bottom: 10px;
    padding-left: 5px;
    z-index: 10;
    letter-spacing: 5px;
    text-align: right;
    color: rgba(255,255,255,0.8);
    top: 60px;
    right: 30px;
    // color: rgba(255,255,255,0.2);
}

.userphoto {
    width: 100vw;
    height: 100vh;
    top: 0;
    letter-spacing: 0;
    z-index: 9;
    left: 0;
    // display: flex;
    box-sizing: border-box;
    // align-items: center;
    // justify-content: center;
    // background-color: yellow;
}

img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.userphoto {
    width: 80vh;
    height: 80vh;
    margin-top: 10vh;
    margin-left: calc(50vw - 40vh);
    object-position: center;
    object-fit: cover !important;
    position: fixed;
    top: 0;
    left: 0;
}

.userphoto img, .react-p5, .react-p5 canvas, .userphoto .p5Seed  {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.userphoto .p5Seed  {
    width: 80vh;
    height: 80vh;
}

.saen__ty {
    width: 600px;
    height: 100vh;
    top: -20px;
    left: calc(50vw - 300px);
    color: white;
    line-height: 130%;
    font-family: 'pixel';
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.saen__ty p {
    height: 100px;
    text-align: center;
    width: 600px;
}

.saen__si {
    width: 600px;
    height: 20px;
    top: 550px;
    left: 300px;
    color: white;
    line-height: 130%;
    font-family: 'pixel';
    text-align: right;
}

.saen__waiting {
    width: 100vw;
    text-align: center;
    top: 30vh;
    font-family: 'pixel';
    opacity: 1;
    font-size: 30px;
    line-height: 50%;
}

.saen__waiting p:first-child {
    margin-bottom: 100px;
} 

.saen__step {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    font-family: 'pixel';
    font-size: 120px;
    position: fixed;
}

.saen__step__s {
    left: 5vw;
    top: 4vh;
}

.saen__step__t {
    left: 23vw;
    top: 12vh;
}

.saen__step__e {
    left: 40vw;
    top: 8vh;
}

.saen__step__p {
    left: 59vw;
    top: 14vh;
}

.saen__step__i {
    left: 76vw;
    top: 47vh;
}

.saen__step__n {
    left: 85vw;
    top: 53vh;
}

.saen__scroll, .saen__stroll {
    color: white;
    top: 0;
    left: 0;
    letter-spacing: 5px;
    font-family: monospace;
    font-size: 30px;
    text-align: center;
    width: 100%;
    top: calc(50vh - 50px);
}

.saen__stroll {
    animation: blinkStroll 0.8s infinite;
}
.saen__scroll {
    animation: blinkScroll 0.8s infinite;
}

.userphoto__info {
    right: 10px;
    bottom: 5px;
    font-family: 'reg';
    font-size: 25px;
    // mix-blend-mode: difference;
    color: white;
    text-shadow: 2px 2px black;
    width: 400px;
    text-align: right;
    line-height: 125%;
    position: absolute;
}

.saen__compost {
    width: 270px;
    top: calc(30vh + 180px);
    left: calc(50vw - 145px);
}

.saen__compost img {
    opacity: 0.4;
}

.saen__compost img:hover {
    transform: scale(3);
    opacity: 1;
    transition-duration: .5s;
    cursor: crosshair;
}

@keyframes blinkStroll {
    0% { opacity: 0; }
    9% { opacity: 0; }
    10% { opacity: 1; }
    30% { opacity: 1; }
    31% { opacity: 0; }
    61% { opacity: 0; }
    62% { opacity: 1; }
    100% { opacity: 1; }
}

@keyframes blinkScroll {
    0% { opacity: 1; }
    9% { opacity: 1; }
    10% { opacity: 0; }
    30% { opacity: 0; }
    31% { opacity: 1; }
    61% { opacity: 1; }
    62% { opacity: 0; }
    100% { opacity: 0; }
}

@keyframes wave {
	0% {
		transform: rotateZ(3deg) translateZ(-1px);
	}
	25%{
		transform: rotateZ(-4deg) translateZ(-1px);
	}
	50% {
		transform: rotateZ(2deg) translateZ(-1px);
	}
	75% {
		transform: rotateZ(-6deg) translateZ(-1px);
	}
	100% {
		transform: rotateZ(3deg) translateZ(-1px);
	}
}

@keyframes glow {
    0% { width:9%; }
    50% { width:15%; }
    100% { width:9%; }
}

@keyframes wave2 {
	0% {
		transform: rotateZ(0deg) translateZ(-1px);
	}
	25%{
		transform: rotateZ(-3deg) translateZ(-1px);
	}
	50% {
		transform: rotateZ(3deg) translateZ(-1px);
	}
	75% {
		transform: rotateZ(-1deg) translateZ(-1px);
	}
	100% {
		transform: rotateZ(0deg) translateZ(-1px);
	}
}

.saen__floater--2 {
    width: 200px;
    right: 40px;
    top: 40px;
}

.saen__floater--3 {
    mix-blend-mode: difference;
    width: 250px;
}

.saen__floater--1 {
    width: 200px;
}

.saen__text__1 {
    z-index: 10000;
}