#kreuzen {
    top: 0;
    left: 0;
}

.kreuzen__title {
    top: 0;
    left: 0;
}

.kreuzen__title__1, .kreuzen__title__2 {
    width: 40vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 60vh;
    font-size: 100px;
    font-family: 'title';
}

.kreuzen__title__2 p span,  .kreuzen__title__1 p span{
    color: transparent;
}

.kreuzen__tag {
    font-size: 40px;
    font-family: 'pixel';
    width: 1000px;
}

.kreuzen__tag__2 {
    margin-top: -130px;
    width: 2000px;
}

.kreuzen__tag__2 p {
    display: inline-block;
    margin-right: 200px;
}

// .userphoto--5 {
//     width: 100vw;
//     height: 100vh;
//     left: 0;
//     top: 0;
// }

// .userphoto--5 img {
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
// }

.kreuzen__binary {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.kreuzen__binary__1, .kreuzen__binary__2 {
    font-family: 'reg';
    font-size: 30px;
    width: 300px;
    height: 50px;
    position: absolute;
    pointer-events: none;
    text-align: center;
}

.kreuzen__binary__1 {
    left: 100px;
    top: 45vh;
}
.kreuzen__binary__2 {
    right: 100px;
    top: 45vh;
}

.kreuzen__man {
    width: 100vw;
    height: 200px;
    left: 0;
    bottom: 0;
    position: absolute;
    z-index: 2;
    pointer-events: none;
}

.kreuzen__man__man {
    // height: 80px;
    width: 100px;
    bottom: -104px;
    position: absolute;
    vertical-align: bottom;
    pointer-events: none;
    object-position: bottom;
    height:200px;
}

.kreuzen__floater {
    pointer-events: none;
}

.kreuzen__man__man img {
    object-position: top;
}

.kreuzen__geschichte {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
}

.kreuzen__geschichte p {
    text-align: left;
    width: 850px;
    margin-top: -100px;
    font-family: 'pixel';
    font-size: 30px;
}

.kreuzen__video {
    width: 100vw;
    height: 100vh;
    z-index: 2;
    padding: 50px;
    box-sizing: border-box;
    padding-left: calc(50vw - 45vh);
}

.kreuzen__video video {
    width: 90vh;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.kreuzen__text__paste__1, .kreuzen__text__copy__1, .kreuzen__text__paste__2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 500px;
}

.kreuzen__text__copy__1, .kreuzen__text__paste__1, .kreuzen__text__paste__2 span {
    color: white;
}

.kreuzen__text__paste__2, .kreuzen__text__copy__1 span, .kreuzen__text__paste__1 span {
    color: transparent;
}

.kreuzen__text__paste__1 {
    display: none;
}

.kreuzen__text__copy {
    top: 200px;
    left: 800px;
    position: absolute;
    cursor: pointer;
    z-index: 1;
    font-family: 'reg';
    font-size: 18px;
}

#kreuzen .keimen__slogan1 {
    width: 1000px;
}

.kreuzen__text__paste {
    top: 150px;
    left: 100px;
    position: absolute;
    cursor: pointer;
    z-index: 1;
    font-family: 'reg';
    font-size: 18px;
}

.kreuzen__copy__pfropfen {
    font-family: 'reg';
    font-size: 18px;
    font-weight: bold;
}

.kreuzen__text__paste p, .kreuzen__text__copy p {
    padding-top: 30px;
}

.kreuzen__dating {
    font-family: 'pixel';
    font-size: 30px;
    z-index: 0;
    padding-left: 50px;
}

.kreuzen__garden {
    font-family: 'pixel';
    font-size: 22px;
    padding: 90px;
    color: orangered;
}

.kreuzen__text__copy__1 {
    animation: slowblink 1.5s infinite linear;
}

.kreuzen__text__paste__2 {
    animation: slowblink 0s infinite linear;
}

@keyframes slowblink {
    0%{ opacity: 0.3; }
    50%{ opacity: 1; }
    100%{ opacity: 0.3; }
}


// FILLES NB
.kreuzen__floater--1 {
    width: 600px;
    z-index: 3;
    margin-left: -300px;
    margin-top: -200px;
}
// BOXES GIF
.kreuzen__floater--2 {
    filter: invert(1);
    width: 600px;
    right: -50px;
    top: 30px;
}
// MACHINE
.kreuzen__floater--4 {
    width: 600px;
    z-index: 1;
    margin-left: -300px;
    margin-top: -250px;
}
// LEAF FRAME
.kreuzen__floater--5 {
    width: 600px;
    z-index: 2;
    margin-left: -300px;
    margin-top: 10px;
}
// FRUIT 1
.kreuzen__floater--6 {
    height: 250px;
    width: 250px;
}
.kreuzen__text__paste, .kreuzen__text__copy {
    width: 600px;
    height: 250px;
    cursor: pointer;
}

// .kreuzen__text__paste:hover, .kreuzen__text__copy:hover {
//     transform: scale(1.1);
// }

.kreuzen__garden {
    pointer-events: none;
}